<template>
  <v-data-table fixed-header
    :headers="recalculationStavkeHeaders"
    :items="recalculationData"
    hide-default-footer
    dense
    id="stavket"
    height="100%"
    item-key="id"
    class="elevation-1"
    style="height: 35vh; overflow-y: auto"
    :items-per-page="-1">
    <template v-slot:item="{ item }">
      <tr>
        <td style="width: 20%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
          {{ item.item_name }}
        </td>
        <td style="width: 20%; text-align: left;">
          {{ formatCurrency(item.quantity / 1000, 1) }}
        </td>
        <td style="width: 20%; text-align: left;">
          {{ item.unit }}
        </td>
        <td style="width: 20%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
          {{ item.action }}
        </td>
        <td style="width: 20%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
          {{ item.date }}
        </td>
        <td style="width: 20%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
          {{ item.reason }}
        </td>
      </tr>
    </template>
  </v-data-table>
</template>
<script>

export default {
  components: {
  },
  inject: ['showMsgBox', 'showLoader', 'hideLoader', 'showAlert'],
  props: {
    recalculationData: Array,
    viewType: String
  },
  data: () => ({
    currency: 'EUR',
    recalculationStavkeHeaders: [
      { text: 'Artikl', value: 'name', align: 'start' },
      { text: 'Količina', value: 'quantity', align: 'start' },
      { text: 'Mj. jedinica', value: 'unit', align: 'start' },
      { text: 'Akcija', value: 'action', align: 'start' },
      { text: 'Datum', value: 'date', align: 'start' },
      { text: 'Razlog', value: 'reason', align: 'start' }
    ]
  }),
  async mounted () {
  },
  methods: {

    formatCurrency (item, divider = 100) {
      return this.$options.filters
        .money(item, divider, this.currency)
        .replace('€', '')
    }
  }
}
</script>
