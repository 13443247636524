<template>
  <div style="font-family: Raleway, sans-serif; padding-top: 10px">
    <table justify="center"
      style="width: 100% !important"
      border="1"
      cellspacing="0"
      id="tableTraffic">
      <tbody>
        <!-- First table: Artikli -->
        <tr class="section-header"
          style="text-align: center; background-color: #d3d3d3;">
          <td colspan="4"
            style="font-weight: bold;">Artikli</td>
        </tr>
        <tr class="column-header"
          style="font-weight: bold; text-align: left; background-color: #d3d3d3;">
          <td>Artikl</td>
          <td>Mj. jedinica</td>
          <td>Kolicina</td>
          <td>Cijena</td>
        </tr>
        <tr v-for="item in deliveryNoteManualData"
          :key="item.id">
          <td>{{ item.artikl }}</td>
          <td>{{ item.jedinica }}</td>
          <td style="text-align: right">
            {{ formatCurrency(item.kolicina, 1) }}
          </td>
          <td style="text-align: right">
            {{ formatCurrency(item.cijena, 1) }}
          </td>
        </tr>

        <!-- Empty row between sections -->
        <tr class="empty-row">
          <td colspan="4">&nbsp;</td>
        </tr>

        <!-- Second table: Povratna naknada -->
        <tr class="section-header"
          style="text-align: center; background-color: #3EA2E6;">
          <td colspan="4"
            style="color: white; font-weight: bold;">Povratna naknada</td>
        </tr>
        <tr class="column-header"
          style="font-weight: bold; text-align: center; background-color: #d3d3d3;">
          <td colspan="2"
            style="width: 50%">Količina</td>
          <td colspan="2"
            style="width: 50%">Ukupna cijena</td>
        </tr>
        <tr v-for="item in refundData"
          :key="item.id">
          <td colspan="2"
            style="text-align: center; width: 50%">{{ item.quantity }}</td>
          <td colspan="2"
            style="text-align: center; width: 50%">{{ item.total }}</td>
        </tr>

        <!-- Empty row between sections -->
        <tr class="empty-row">
          <td colspan="4">&nbsp;</td>
        </tr>

        <!-- Third table: Ukupni porez za račun -->
        <tr class="section-header"
          style="text-align: center; background-color: #dc322f;">
          <td colspan="4"
            style="color: white; font-weight: bold;">Ukupni porez za račun</td>
        </tr>
        <tr class="column-header"
          style="font-weight: bold; text-align: center; background-color: #d3d3d3;">
          <td style="width: 33.33%">Postotak</td>
          <td style="width: 33.33%">Osnovica</td>
          <td colspan="2"
            style="width: 33.34%">Iznos</td>
        </tr>
        <tr v-for="item in taxData"
          :key="item.id">
          <td style="text-align: center; width: 33.33%">{{ item.percentage }}</td>
          <td style="text-align: center; width: 33.33%">{{ item.base }}</td>
          <td colspan="2"
            style="text-align: center; width: 33.34%">{{ item.amount }}</td>
        </tr>

        <!-- Empty row between sections -->
        <tr class="empty-row">
          <td colspan="4">&nbsp;</td>
        </tr>

        <!-- Fourth table: Ukupni iznos računa -->
        <tr class="section-header"
          style="text-align: center; background-color: #B7E1CD;">
          <td colspan="4"
            style="font-weight: bold;">Ukupni iznos računa</td>
        </tr>
        <tr v-for="item in totalData"
          :key="item.id">
          <td colspan="4"
            style="text-align: center">{{ item.total }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    deliveryNoteManualData: Array,
    refundData: Array,
    taxData: Array,
    totalData: Array,
    viewType: String
  },
  data: () => ({
    currency: 'EUR'
  }),
  methods: {
    formatCurrency (item, divider = 100) {
      return this.$options.filters
        .money(item, divider, this.currency)
        .replace('€', '')
    }
  }
}
</script>

<style scoped>
table {
  border-collapse: collapse;
}

td {
  padding: 4px;
  border: 1px solid #ddd;
}

.section-header td {
  height: 24px;
  border: 1px solid black;
}

.column-header td {
  height: 24px;
  border: 1px solid black;
}

.empty-row td {
  height: 10px;
  border: none;
}
</style>
