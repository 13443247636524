<template>
  <v-data-table fixed-header
    :headers="otpisStavkeHeaders"
    :items="stavke"
    hide-default-footer
    dense
    id="stavket"
    height="100%"
    item-key="id"
    class="elevation-1"
    style="height: 35vh; overflow-y: auto"
    :items-per-page="-1">
    <template v-slot:item="{ item }">
      <tr>
        <!-- Fiksna širina stupca za naziv -->
        <td style="width: 50%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
          {{ item.name }}
        </td>
        <!-- Fiksna širina stupca za količinu -->
        <td style="width: 50%; text-align: left;">
          {{ formatCurrency(item.quantity, 1) }}
        </td>
      </tr>
    </template>
  </v-data-table>
</template>
<script>

export default {
  components: {
  },
  inject: ['showMsgBox', 'showLoader', 'hideLoader', 'showAlert'],
  props: {
    stavke: Array,
    viewType: String
  },
  data: () => ({
    currency: 'EUR',
    otpisStavkeHeaders: [
      { text: 'Artikl', value: 'name', align: 'start' },
      { text: 'Količina', value: 'quantity', align: 'start' }
    ]
  }),
  async mounted () {
  },
  methods: {
    formatCurrency (item, divider = 100) {
      return this.$options.filters
        .money(item, divider, this.currency)
        .replace('€', '')
    }
  }
}
</script>
