<template>
  <div style="font-family: Raleway, sans-serif;">
    <table justify="center"
      style="width: 100% !important; font-family: 'Roboto-Regular'"
      class="mb-10"
      cellspacing="0"
      id="firma">
      <tbody>
        <tr>
          <td class="font-weight-bold">Datum</td>
          <td>{{ formatDate(datumKreiranja) }}</td>
        </tr>
        <tr>
          <td class="font-weight-bold">Dokument</td>
          <td>{{ deliveryNumber }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import moment from 'moment'

export default {
  components: {
  },
  inject: ['showMsgBox', 'showLoader', 'hideLoader', 'showAlert'],
  props: {
    stavke: Array,
    showTableData: Boolean,
    deliveryNumber: String,
    datumKreiranja: Number,
    viewType: String
  },
  data: () => ({
    currency: 'EUR'
  }),
  async mounted () {
  },
  methods: {
    formatDate: function (date) {
      if (date === '') {
        return ''
      }
      date = new Date(date * 1000)

      return moment(date).format('DD.MM.YYYY')
    }
  }
}
</script>
<style scoped>
.enabledbutton {
  background-color: #2c97c5 !important;
  border-color: #2c97c5 !important;
  color: white;
  font-weight: 700;
}

.naslov {
  background-color: #d3d3d3;
  border: 1px solid black;
}

.selectedrow-456 {
  background-color: #2c97c5 !important;
  border-color: #2c97c5 !important;
  color: white;
  font-weight: 700;
}

.createDelivery>>>.vm--modal {
  border-radius: 25px !important;
}

.editDelivery>>>.vm--modal {
  border-radius: 25px !important;
}
</style>
