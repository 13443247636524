<template>
  <v-row>
    <v-col cols="8">
      <v-data-table fixed-header
        :headers="primkaRucniUpisStavkeHeaders"
        :items="deliveryNoteManualData"
        hide-default-footer
        dense
        id="stavket"
        height="100%"
        item-key="id"
        class="elevation-1"
        style="height: 35vh; overflow-y: auto"
        :items-per-page="-1">
        <template v-slot:item="{ item }">
          <tr>
            <!-- Fiksna širina stupca za naziv -->
            <td style="width: 2ch; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
              {{ item.artikl }}
            </td>
            <td style="width: 20%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
              {{ item.jedinica }}
            </td>
            <!-- Fiksna širina stupca za količinu -->
            <td style="width: 20%; text-align: left;">
              {{ formatCurrency(item.kolicina, 1) }}
            </td>

            <td style="width: 20%; text-align: left;">
              {{ formatCurrency(item.cijena, 1) }}
            </td>

            <!-- <td style="width: 20%; text-align: left;">
          {{ formatCurrency(item.ukupno, 1) }}
        </td> -->
          </tr>
        </template>
      </v-data-table>
    </v-col>

    <v-col cols="4"
      class="d-flex flex-column"
      style="height: 10vh; padding: 4px;">
      <!-- First Card: Povratna naknada -->
      <v-card v-if="refundData && refundData.length > 0"
        class="mb-2 compact-card">
        <v-card-title style="background-color: #3EA2E6; padding: 2px 8px; min-height: 24px; font-size: 14px;">
          <div style="width: 100%; text-align: center; font-weight: bold; color: white;">
            Povratna naknada
          </div>
        </v-card-title>
        <v-data-table fixed-header
          :headers="refundDataHeaders"
          :items="refundData"
          hide-default-footer
          dense
          id="stavket"
          style="max-height: 70px;"
          item-key="id"
          :items-per-page="2">

          <template v-slot:header.quantity="{ header }">
            <div style="font-weight: bold !important; text-align: center; font-size: 12px;">
              {{ header.text }}</div>
          </template>

          <template v-slot:header.total="{ header }">
            <div style="font-weight: bold !important; text-align: center; font-size: 12px;">{{ header.text }}</div>
          </template>

          <template v-slot:item="{ item }">
            <tr style="height: 24px;">
              <td
                style="white-space: nowrap; overflow: hidden; text-align: center; text-overflow: ellipsis; border: 1px solid #ddd; font-size: 12px; padding: 0 4px;">
                {{ item.quantity }}
              </td>
              <td
                style="white-space: nowrap; overflow: hidden; text-align: center; text-overflow: ellipsis; border: 1px solid #ddd; font-size: 12px; padding: 0 4px;">
                {{ item.total }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>

      <!-- Second Card: Ukupni porez za račun -->
      <v-card class="mb-2 compact-card">
        <v-card-title style="background-color: #dc322f; padding: 2px 8px; min-height: 24px; font-size: 14px;">
          <div style="width: 100%; text-align: center; font-weight: bold; color: white;">
            Ukupni porez za račun
          </div>
        </v-card-title>
        <div style="border-top: 1px solid rgba(0, 0, 0, 0.12);">
          <table style="width: 100%; border-collapse: collapse; font-size: 12px;">
            <thead>
              <tr style="height: 30px; background-color: #f5f5f5;">
                <th
                  style="text-align: center; padding: 2px 4px; border: 1px solid #ddd; font-weight: bold; font-size: 12px; color: rgba(0, 0, 0, 0.6); width: 33.33%;">
                  Postotak</th>
                <th
                  style="text-align: center; padding: 2px 4px; border: 1px solid #ddd; font-weight: bold; font-size: 12px; color: rgba(0, 0, 0, 0.6); width: 33.33%;">
                  Osnovica</th>
                <th
                  style="text-align: center; padding: 2px 4px; border: 1px solid #ddd; font-weight: bold; font-size: 12px; color: rgba(0, 0, 0, 0.6); width: 33.33%;">
                  Iznos</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in taxData"
                :key="item.id"
                style="height: 24px;">
                <td
                  style="white-space: nowrap; overflow: hidden; text-align: center; text-overflow: ellipsis; border: 1px solid #ddd; font-size: 12px; padding: 0 4px; width: 33.33%;">
                  {{ item.percentage }}</td>
                <td
                  style="white-space: nowrap; overflow: hidden; text-align: center; text-overflow: ellipsis; border: 1px solid #ddd; font-size: 12px; padding: 0 4px; width: 33.33%;">
                  {{ item.base }}</td>
                <td
                  style="white-space: nowrap; overflow: hidden; text-align: center; text-overflow: ellipsis; border: 1px solid #ddd; font-size: 12px; padding: 0 4px; width: 33.33%;">
                  {{ item.amount }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-card>

      <!-- Third Card: Ukupni iznos računa -->
      <v-card class="compact-card">
        <v-card-title style="background-color: #B7E1CD; padding: 2px 8px; min-height: 24px; font-size: 14px;">
          <div style="width: 100%; text-align: center; font-weight: bold; color: black;">
            Ukupni iznos računa
          </div>
        </v-card-title>
        <v-data-table fixed-header
          :items="totalData"
          hide-default-footer
          dense
          id="stavket"
          style="max-height: 70px;"
          item-key="id"
          :items-per-page="1">
          <template v-slot:item="{ item }">
            <tr style="height: 24px;">
              <td
                style="white-space: nowrap; overflow: hidden; text-align: center; text-overflow: ellipsis; border: 1px solid #ddd; font-size: 12px; padding: 0 4px;">
                {{ item.total }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>

    </v-col>
  </v-row>
</template>
<script>

export default {
  components: {
  },
  inject: ['showMsgBox', 'showLoader', 'hideLoader', 'showAlert'],
  props: {
    deliveryNoteManualData: Array,
    refundData: Array,
    taxData: Array,
    totalData: Array,
    viewType: String
  },
  computed: {
    calculateTaxTableHeight () {
      // Header height (approximately 30px) + (number of rows × row height)
      const headerHeight = 30
      const rowHeight = 24 // As defined in your tr style

      // Get the number of rows in taxData, or default to 1 if empty
      const rowCount = this.taxData ? this.taxData.length : 1

      // Calculate total height needed
      // Add a larger buffer (14px) to ensure no cutting off, especially for 4 rows
      return headerHeight + (rowCount * rowHeight) + 14
    }
    // ... your other computed properties
  },
  data: () => ({
    currency: 'EUR',
    primkaRucniUpisStavkeHeaders: [
      { text: 'Artikl', value: 'artikl' },
      { text: 'Mj. jedinica', value: 'jedinica' },
      { text: 'Količina', value: 'kolicina' },
      { text: 'Cijena', value: 'cijena' }
      // { text: 'Ukupno', value: 'ukupno' }
    ],
    refundDataHeaders: [
      { text: 'Količina', value: 'quantity', sortable: false, width: 50 },
      { text: 'Ukupna cijena', value: 'total', sortable: false, width: 50 }
    ],

    taxDataHeaders: [
      { text: 'Postotak', value: 'quantity', sortable: false },
      { text: 'Osnovica', value: 'total', sortable: false },
      { text: 'Iznos', value: 'total', sortable: false }
    ]
  }),
  async mounted () {
  },
  methods: {
    formatCurrency (item, divider = 100) {
      return this.$options.filters
        .money(item, divider, this.currency)
        .replace('€', '')
    }
  }
}
</script>

<style>
.compact-card {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) !important;
}

.v-data-table>>>th {
  padding: 0 4px !important;
  height: 24px !important;
}

.v-data-table>>>td {
  padding: 0 4px !important;
  height: 24px !important;
}
</style>
