<template>
  <v-data-table fixed-header
    :headers="prebacivanjeStavkeHeaders"
    :items="transferData"
    hide-default-footer
    dense
    id="stavket"
    height="100%"
    item-key="id"
    class="elevation-1"
    style="height: 35vh; overflow-y: auto"
    :items-per-page="-1">
    <template v-slot:item="{ item }">
      <tr>
        <td>{{ item.source_item_name }}</td>
        <td>
          {{ formatCurrency(item.quantity / 1000, 1) }}
        </td>
        <td>{{ item.destination_item_name }}</td>
      </tr>
    </template>
  </v-data-table>
</template>
<script>

export default {
  components: {
  },
  inject: ['showMsgBox', 'showLoader', 'hideLoader', 'showAlert'],
  props: {
    transferData: Array,
    viewType: String
  },
  data: () => ({
    currency: 'EUR',
    prebacivanjeStavkeHeaders: [
      { text: 'Artikl koji je prebačen', value: 'source_item_name' },
      { text: 'Količina', value: 'quantity' },
      { text: 'Artikl na koji je prebačeno', value: 'destination_item_name' }
    ]
  }),
  async mounted () {
  },
  methods: {
    formatCurrency (item, divider = 100) {
      return this.$options.filters
        .money(item, divider, this.currency)
        .replace('€', '')
    }
  }
}
</script>
