<template>
  <div>
    <v-overlay v-if="loading"
      :absolute="absolute"
      :opacity="opacity"
      :value="overlay"
      style="z-index: 100">
      <div v-if="loading"
        class="text-center"
        style="
            position: absolute;
            z-index: 101;
            top: 50%;
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
          ">
        <v-progress-circular indeterminate
          size="86"
          color="primary">
        </v-progress-circular>
      </div>
    </v-overlay>
    <v-card justify="center"
      style="overflow-y: hidden"
      dense>

      <v-card-actions dense>
        <v-container dense>
          <v-card class="mt-0 pt-2 mb-2 pb-2">
            <v-card-actions class="mt-0 pt-2 mb-0 pb-0">
              <v-row dense
                class="pb-1">
                <v-col cols="6"
                  dense
                  class="mt-0 pt-0 mb-0 pb-0"><v-select hide-details
                    label="Odaberite prodajno mjesto"
                    v-model="selectedLocation"
                    :items="pointsOfSale"
                    item-text="name"
                    item-value="value"
                    return-object
                    @change="setWarehouseChoices"
                    bottom
                    outlined
                    dense
                    class="ml-2 pb-0">
                  </v-select></v-col>
                <v-col class="mt-0 pt-0 mb-0 pb-0"><v-select hide-details
                    label="Odaberite skladište"
                    class="mr-2 pb-0"
                    v-model="selectedWarehouse"
                    :items="warehouses"
                    item-text="name"
                    item-value="value"
                    bottom
                    outlined
                    return-object
                    dense
                    @change="warehouseSelected">
                  </v-select></v-col>
              </v-row>
            </v-card-actions>
          </v-card>
          <v-card class="mt-0 pt-0 mb-2 pb-0"
            style="justify: center">
            <v-card-actions style="justify: center">
              <v-row>
                <v-col cols="12"
                  style="justify: center">
                  <v-row>
                    <div class="pt-3 pr-2"
                      style="min-width: 15% !important; max-width: 15% !important;">
                      <v-menu v-model="reportFromMenu"
                        offset-y
                        :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field outlined
                            hide-details
                            :value="formatDateFrom"
                            label="Od datuma"
                            dense
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="dateFrom"
                          no-title
                          scrollable
                          @input="reportFromMenu = false">
                        </v-date-picker>
                      </v-menu>
                    </div>
                    <div class="pt-3 pr-2"
                      style="min-width: 15% !important; max-width: 15% !important;">
                      <v-menu v-model="reportToMenu"
                        offset-y
                        :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field outlined
                            hide-details
                            :value="formatDateTo"
                            label="Do datuma"
                            dense
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="dateTo"
                          no-title
                          scrollable
                          @input="reportToMenu = false">
                        </v-date-picker>
                      </v-menu>
                    </div>
                    <div class="pt-3 pr-2"
                      style="min-width: 12% !important; max-width: 12% !important;">
                      <v-text-field outlined
                        hide-details
                        label="Broj dokumenta"
                        dense
                        v-model="deliveryNumberSearch"></v-text-field>
                    </div>
                    <div class="pt-3 pr-2"
                      style="min-width: 15% !important; max-width: 15% !important;">
                      <v-autocomplete hide-details
                        label="Dobavljač"
                        class="mr-0 pb-0"
                        v-model="selectedAssociate"
                        :items="associates"
                        item-text="name"
                        item-value="value"
                        clearable
                        bottom
                        outlined
                        return-object
                        dense>
                      </v-autocomplete>
                    </div>
                    <div style="
                      min-width: 30% !important;
                      padding-top: 12px;
                      padding-right: 10px;
                      overflow: auto;
                    ">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn dense
                            class="mb-3 ml-2 enabledbutton mr-2"
                            height="2.5rem"
                            :disabled="disableButton"
                            v-bind="attrs"
                            v-on="on"
                            @click="getDocuments()">
                            <v-icon>mdi-magnify</v-icon>
                          </v-btn>
                        </template>
                        <span>Dohvati dokumente</span>
                      </v-tooltip>
                      <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                              <v-btn class="mb-3 enabledbutton mr-2"
                                style="min-width: 44px !important"
                                height="2.5rem"
                                v-bind="tooltipAttrs"
                                v-on="Object.assign({}, on, tooltipOn)">
                                Novi dokument
                              </v-btn>
                            </template>
                            <span>Uredi</span>
                          </v-tooltip>
                        </template>
                        <v-list>
                          <v-list-item @click="showDoc">
                            <v-list-item-title>Primka</v-list-item-title>
                          </v-list-item>

                          <v-list-item @click="showDeliveryNote">
                            <v-list-item-title>Primka ručni unos</v-list-item-title>
                          </v-list-item>

                          <v-list-item @click="showWriteOff">
                            <v-list-item-title>Otpis</v-list-item-title>
                          </v-list-item>

                          <v-list-item @click="showTransfer">
                            <v-list-item-title>Prebacivanje</v-list-item-title>
                          </v-list-item>

                          <v-list-item @click="showRecalculation">
                            <v-list-item-title>Usklađivanje stanja skladišta</v-list-item-title>
                          </v-list-item>

                        </v-list>
                      </v-menu>

                      <v-menu v-if="selectedRows.length > 0"
                        offset-y>
                        <template v-slot:activator="{ on }">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                              <v-btn class="mb-3 mr-2"
                                style="min-width: 44px !important"
                                height="2.5rem"
                                v-bind="tooltipAttrs"
                                v-on="Object.assign({}, on, tooltipOn)">
                                <v-icon>mdi-download</v-icon>
                              </v-btn>
                            </template>
                            <span>Preuzmi</span>
                          </v-tooltip>
                        </template>
                        <v-list>
                          <v-list-item @click="changeWidth()">
                            <v-list-item-title>Preuzmi PDF</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="generateExcel()">
                            <v-list-item-title>Preuzmi Excel</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                      <v-menu v-if="
                        selectedRows.length > 0 &&
                        primke.some(
                          (primka) =>
                            selectedRows.includes(primka.id) &&
                            primka.type === 'delivery_notes'
                        )
                      "
                        offset-y>
                        <template v-slot:activator="{ on }">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                              <v-btn class="mb-3 mr-2"
                                style="min-width: 44px !important"
                                height="2.5rem"
                                v-bind="tooltipAttrs"
                                v-on="Object.assign({}, on, tooltipOn)">
                                <v-icon>mdi-pencil</v-icon>
                              </v-btn>
                            </template>
                            <span>Uredi</span>
                          </v-tooltip>
                        </template>
                        <v-list>

                          <v-list-item
                            v-if="primke.some(primka => (selectedRows.includes(primka.id) && primka.doc_type !== 'recalculation'))"
                            @click="deleteDeliveryNote">
                            <v-list-item-title>Obriši dokument</v-list-item-title>
                          </v-list-item>

                          <v-list-item
                            v-if="primke.some(primka => selectedRows.includes(primka.id) && (primka.doc_type !== 'deliverynote_manual' && primka.doc_type !== 'recalculation'))"
                            @click="editDeliveryNote">
                            <v-list-item-title>{{ showEditTitle() }}</v-list-item-title>
                          </v-list-item>

                          <v-list-item
                            v-if="primke.some(primka => selectedRows.includes(primka.id) && primka.doc_type === 'deliverynote_manual')"
                            @click="editDeliveryNoteManual">
                            <v-list-item-title>Uredi primku</v-list-item-title>
                          </v-list-item>

                          <v-list-item
                            v-if="primke.some(primka => selectedRows.includes(primka.id) && primka.doc_type === 'recalculation')"
                            @click="showRecalculationEdit">
                            <v-list-item-title>Uredi usklađivanje</v-list-item-title>
                          </v-list-item>

                          <v-list-item v-if="primke.some(primka => selectedRows.includes(primka.id) && primka.type ===
                            'delivery_notes' &&
                            primka.doc_type !== 'creditnote' && primka.doc_type !== 'recalculation')"
                            @click="editCreditNote">
                            <v-list-item-title>Knjižno odobrenje</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
          <v-card class="mb-2"
            style="justify: center">
            <v-card-actions style="justify: center">
              <v-row>
                <v-col cols="6">
                  <v-data-table height="100%"
                    hide-default-footer
                    :headers="headers"
                    fixed-header
                    disable-pagination
                    :items-per-page="-1"
                    dense
                    :items="primke"
                    item-key="id"
                    v-model="selectedRows"
                    class="elevation-1"
                    style="height: 256px; overflow-y: auto; cursor: pointer">
                    <template v-slot:item="{ item }">
                      <tr :class="selectedRows.indexOf(item.id) > -1
                        ? 'selectedrow-456'
                        : ''
                        "
                        @click="rowClicked(item)">
                        <td>{{ formatDate(item.order_date) }}</td>
                        <td>{{ item.delivery_number }}</td>
                        <td>{{ item.customer_name }}</td>
                        <td style="text-align: right">
                          {{ formatCurrency(item.ukupno) }}
                        </td>
                        <td style="text-align: right">
                          {{
                            showDocumentType(
                              item.realType,
                              item.type,
                              item.doc_type
                            )
                          }}
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-col>
                <v-col cols="6">
                  <v-row>
                    <v-col cols="12">
                      <!-- DESNA TABLICA OPCE INFO GUI -->
                      <InfoRightTable
                        v-if="!selectedRows || selectedRows.length === 0 || !primke || (primke.some(primka => selectedRows.includes(primka.id) && primka.doc_type !== 'deliverynote_manual'))"
                        :warehouseName="warehouseName"
                        :dobavljac="dobavljac"
                        :fobDescription="fobDescription"
                        :datumKreiranja="datumKreiranja"
                        :deliveryNumber="deliveryNumber"
                        :ukupnoBezPdv="ukupnoBezPdv"
                        :inputTaxTotal="inputTaxTotal"
                        :ukupnoBrutto="ukupnoBrutto"
                        :selectedDeliveryNote="selectedDeliveryNote"
                        :skladiste="skladiste"
                        :skladisteLabel="skladisteLabel"
                        :viewType="viewType" />

                      <InfoRightManualTable
                        v-if="primke.some(primka => selectedRows.includes(primka.id) && primka.doc_type === 'deliverynote_manual')"
                        :warehouseName="warehouseName"
                        :dobavljac="dobavljac"
                        :fobDescription="fobDescription"
                        :datumKreiranja="datumKreiranja"
                        :deliveryNumber="deliveryNumber"
                        :ukupnoBezPdv="ukupnoBezPdv"
                        :inputTaxTotal="inputTaxTotal"
                        :ukupnoBrutto="ukupnoBrutto"
                        :selectedDeliveryNote="selectedDeliveryNote"
                        :skladiste="skladiste"
                        :skladisteLabel="skladisteLabel"
                        :viewType="viewType" />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
          <v-card style="justify: center">
            <v-card-actions style="jusitfy: center">
              <v-row>
                <v-col cols="12">
                  <!-- PRIMKA ARTIKLI TABLICA GUI -->
                  <PrimkaInfoTable
                    v-if="!selectedRows || (primke.some(primka => selectedRows.includes(primka.id) && (primka.doc_type !== 'writeoff' && primka.doc_type !== 'transfer' && primka.doc_type !== 'deliverynote_manual' && primka.doc_type !== 'recalculation')))"
                    :stavke="stavke"
                    :viewType="viewType" />

                  <!-- WRITE OFF ARTIKLI TABLICA GUI -->
                  <WriteOffInfoTable
                    v-if="primke.some(primka => selectedRows.includes(primka.id) && primka.doc_type === 'writeoff')"
                    :stavke="stavke"
                    :viewType="viewType" />

                  <!-- TRANSFER ARTIKLI TABLICA GUI -->
                  <TransferInfoTable
                    v-if="primke.some(primka => selectedRows.includes(primka.id) && primka.doc_type === 'transfer')"
                    :transferData="transferData"
                    :viewType="viewType" />

                  <!-- PRIMKA RUCNI UPIS ARTIKLI TABLICA GUI -->
                  <PrimkaInfoManualTable v-if="primke.some(primka => selectedRows.includes(primka.id) && primka.doc_type ===
                    'deliverynote_manual')"
                    :refundData="refundData"
                    :taxData="taxData"
                    :totalData="totalData"
                    :deliveryNoteManualData="deliveryNoteManualData"
                    :viewType="viewType" />

                  <!-- RECALCULATION TABLICA GUI -->
                  <RecalculationInfoTable
                    v-if="primke.some(primka => selectedRows.includes(primka.id) && primka.doc_type === 'recalculation')"
                    :recalculationData="recalculationData"
                    :viewType="viewType" />
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-container>
      </v-card-actions>
      <!-- EXPORT TABLICE -->
      <div class="d-flex justify-center"
        style="display: none !important">
        <div id="table_info"
          justify="center"
          style="
          max-width: 1200px;
          min-width: 1200px;
          font-size: 16px;
          font-family: 'Roboto-Regular';
          display: none;
        ">
          <div style="font-family: Raleway, sans-serif; padding-bottom: 10px">
            <table justify="center"
              style="width: 100% !important; border: 0 !important"
              cellspacing="0"
              id="title">
              <thead>
                <tr style="text-align: center">
                  <th
                    v-if="primke.some(primka => selectedRows.includes(primka.id) && (primka.doc_type !== 'writeoff' && primka.doc_type !== 'transfer' && primka.doc_type !== 'recalculation'))"
                    colspan="2">{{ this.documentType.name.toUpperCase() }}</th>

                  <th v-if="primke.some(primka => selectedRows.includes(primka.id) && primka.doc_type === 'writeoff')"
                    colspan="2">Otpis</th>

                  <th
                    v-if="primke.some(primka => selectedRows.includes(primka.id) && primka.doc_type === 'recalculation')"
                    colspan="2">Usklađivanje skladišta</th>

                  <th v-if="primke.some(primka => selectedRows.includes(primka.id) && primka.doc_type === 'transfer')"
                    colspan="2">{{ this.documentType.name.toUpperCase() }}</th>
                </tr>
              </thead>
            </table>
          </div>

          <!-- PRIMKA I PRIMKA RUCNI UNOS - INFO EXPORT TABLICA -->
          <BasicInfoPrimkaAndManual
            v-if="primke.some(primka => selectedRows.includes(primka.id) && (primka.doc_type !== 'writeoff' && primka.doc_type !== 'transfer' && primka.doc_type !== 'recalculation'))"
            :warehouseName="warehouseName"
            :dobavljac="dobavljac"
            :fobDescription="fobDescription"
            :datumKreiranja="datumKreiranja"
            :deliveryNumber="deliveryNumber"
            :ukupnoBezPdv="ukupnoBezPdv"
            :inputTaxTotal="inputTaxTotal"
            :ukupnoBrutto="ukupnoBrutto"
            :selectedDeliveryNote="selectedDeliveryNote"
            :viewType="viewType" />

          <!-- PRIMKA ARTIKLI - EXPORT TABLICA -->
          <PrimkaPrintTable
            v-if="primke.some(primka => selectedRows.includes(primka.id) && (primka.doc_type !== 'writeoff' && primka.doc_type !== 'transfer' && primka.doc_type !== 'deliverynote_manual' && primka.doc_type !== 'recalculation'))"
            :stavke="stavke"
            :showTableData="showTableData"
            :viewType="viewType" />

          <!-- WRITE OFF I TRANSFER - INFO EXPORT TABLICA -->
          <CombinedBasicInfoPrintTransferWriteOff
            v-if="primke.some(primka => selectedRows.includes(primka.id) && (primka.doc_type === 'writeoff' || primka.doc_type === 'transfer'))"
            :datumKreiranja="datumKreiranja"
            :deliveryNumber="deliveryNumber"
            :viewType="viewType" />

          <!-- RECALCULATION - INFO EXPORT TABLICA -->
          <BasicInfoRecalculation
            v-if="primke.some(primka => selectedRows.includes(primka.id) && primka.doc_type === 'recalculation')"
            :datumKreiranja="datumKreiranja"
            :brojDokumenta="brojDokumenta"
            :deliveryNumber="deliveryNumber"
            :viewType="viewType" />

          <!-- WRITE OFF ARTIKLI - EXPORT TABLICA -->
          <WriteOffPrintTable
            v-if="primke.some(primka => selectedRows.includes(primka.id) && primka.doc_type === 'writeoff')"
            :stavke="stavke"
            :showTableData="showTableData"
            :viewType="viewType" />

          <!-- TRANSFER OFF ARTIKLI - EXPORT TABLICA -->
          <TransferPrintTable
            v-if="primke.some(primka => selectedRows.includes(primka.id) && primka.doc_type === 'transfer')"
            :transferData="transferData"
            :stavke="stavke"
            :showTableData="showTableData"
            :viewType="viewType" />

          <!-- PRIMKA RUCNI UPIS ARTIKLI - EXPORT TABLICA -->
          <PrimkaPrintManualTable
            v-if="primke.some(primka => selectedRows.includes(primka.id) && primka.doc_type === 'deliverynote_manual')"
            :stavke="stavke"
            :refundData="refundData"
            :taxData="taxData"
            :totalData="totalData"
            :deliveryNoteManualData="deliveryNoteManualData"
            :showTableData="showTableData"
            :viewType="viewType" />

          <!-- RECALCULATION ARTIKLI - EXPORT TABLICA -->
          <RecalculationPrintTable
            v-if="primke.some(primka => selectedRows.includes(primka.id) && primka.doc_type === 'recalculation')"
            :recalculationData="recalculationData"
            :datumKreiranja="datumKreiranja"
            :brojDokumenta="brojDokumenta"
            :showTableData="showTableData"
            :viewType="viewType" />

        </div>
      </div>
      <modal v-if="!modal"
        height="auto"
        width="90%"
        scrollable
        draggable
        :clickToClose="false"
        resizable
        style="z-index: 200"
        name="documents"
        class="createDelivery">
        <component style="border-radius: 25px !important"
          :is="primkaobjekt"
          :modal="true"
          @closeModal="hideDoc()">
        </component>
      </modal>

      <modal v-if="!modal"
        height="auto"
        width="90%"
        :clickToClose="false"
        scrollable
        draggable
        resizable
        style="z-index: 200; border-radius: 25px !important"
        name="edit"
        class="editDelivery">
        <component style="border-radius: 25px !important"
          :is="primkaEditObjekt"
          :modal="true"
          @closeModal="hideDocEdit()"
          :primka="primkaZaEdit"
          :tipDokumenta="tipDokumenta"
          :akcija="akcija"></component>
      </modal>

      <modal v-if="!modal"
        height="auto"
        width="90%"
        scrollable
        draggable
        :clickToClose="false"
        resizable
        style="z-index: 200"
        name="writeoff"
        class="createDelivery">
        <component style="border-radius: 25px !important"
          :is="writeOffObjekt"
          :modal="true"
          @closeModal="hideWriteOff()">
        </component>
      </modal>

      <modal v-if="!modal"
        height="auto"
        width="90%"
        scrollable
        draggable
        :clickToClose="false"
        resizable
        style="z-index: 200"
        name="transfer"
        class="createDelivery">
        <component style="border-radius: 25px !important"
          :is="transferObjekt"
          :modal="true"
          @closeModal="hideTransfer()">
        </component>
      </modal>

      <modal v-if="!modal"
        height="auto"
        width="90%"
        scrollable
        draggable
        :clickToClose="false"
        resizable
        style="z-index: 200"
        name="deliveryNoteManual"
        class="createDelivery">
        <component style="border-radius: 25px !important"
          :is="primkaRucniUnosObjekt"
          :modal="true"
          @closeModal="hideDeliveryNote()">
        </component>
      </modal>

      <modal v-if="!modal"
        height="auto"
        width="90%"
        :clickToClose="false"
        scrollable
        draggable
        resizable
        style="z-index: 200; border-radius: 25px !important"
        name="editManual"
        class="editDelivery">

        <component style="border-radius: 25px !important"
          :is="primkaEditManualObjekt"
          :modal="true"
          @closeModal="hideDocManualEdit()"
          :primka="primkaZaEdit"
          :tipDokumenta="tipDokumenta"
          :akcija="akcija"></component>
      </modal>

      <modal v-if="!modal"
        height="auto"
        width="90%"
        scrollable
        draggable
        :clickToClose="false"
        resizable
        style="z-index: 200"
        name="recalculation"
        class="createDelivery">
        <component style="border-radius: 25px !important"
          :is="recalculationObjekt"
          :modal="true"
          @closeModal="hideRecalculation()">
        </component>
      </modal>

      <modal v-if="!modal"
        height="auto"
        width="90%"
        scrollable
        draggable
        :clickToClose="false"
        resizable
        style="z-index: 200"
        name="recalculationEdit"
        class="createDelivery">
        <component style="border-radius: 25px !important"
          :is="recalculationEditObjekt"
          :modal="true"
          :recalculation="primkaZaEdit"
          :data="recalculationData"
          :akcija="akcija"
          @closeModal="hideRecalculationEdit()">
        </component>
      </modal>

    </v-card>
  </div>
</template>
<script>
import state from '@/state'
import moment from 'moment'
import { auth, df, functions } from '@/plugins/firebase'
import {
  initPdf,
  setTable,
  setPageNumber
} from '@/modules/all-reports/pdf/config.js'
import 'jspdf-autotable'
import { saveExcel } from '@/modules/all-reports/xlsx/config.js'
import { clone } from '@/plugins/utils'
import PrimkaInfoTable from './tables/PrimkaInfoTable.vue'
import TransferInfoTable from './tables/TransferInfoTable.vue'
import PrimkaInfoManualTable from './tables/PrimkaInfoManualTable.vue'
import WriteOffInfoTable from './tables/WriteOffInfoTable.vue'
import RecalculationInfoTable from './tables/RecalculationInfoTable.vue'

import PrimkaPrintManualTable from './tables/print/PrimkaPrintManualTable.vue'
import TransferPrintTable from './tables/print/TransferPrintTable.vue'
import WriteOffPrintTable from './tables/print/WriteOffPrintTable.vue'
import CombinedBasicInfoPrintTransferWriteOff from './tables/print/CombinedBasicInfoPrintTransferWriteOff.vue'
import PrimkaPrintTable from './tables/print/PrimkaPrintTable.vue'
import BasicInfoPrimkaAndManual from './tables/print/BasicInfoPrimkaAndManual.vue'
import InfoRightTable from './tables/InfoRightTable.vue'
import InfoRightManualTable from './tables/InfoRightManualTable.vue'
import BasicInfoRecalculation from './tables/print/BasicInfoRecalculation.vue'
import RecalculationPrintTable from './tables/print/RecalculationPrintTable.vue'

export default {
  components: {
    PrimkaInfoTable,
    PrimkaInfoManualTable,
    TransferInfoTable,
    WriteOffInfoTable,
    RecalculationInfoTable,
    PrimkaPrintManualTable,
    TransferPrintTable,
    WriteOffPrintTable,
    CombinedBasicInfoPrintTransferWriteOff,
    BasicInfoRecalculation,
    BasicInfoPrimkaAndManual,
    PrimkaPrintTable,
    InfoRightTable,
    RecalculationPrintTable,
    InfoRightManualTable,
    PrimkaObjekt: () =>
      import(
        '@/modules/point-of-sale/components/warehouse/warehouse-module/WarehouseDocumentsv2.vue'
      ),
    PrimkaRucniUnosObjekt: () =>
      import(
        '@/modules/point-of-sale/components/warehouse/warehouse-module/WarehouseDocumentsv2Manual.vue'
      ),
    PrimkaEditObjekt: () =>
      import(
        '@/modules/point-of-sale/components/warehouse/warehouse-module/DeliveryNoteEdit.vue'
      ),
    WriteOffObjekt: () =>
      import(
        '@/modules/point-of-sale/components/warehouse/warehouse-module/WriteOff.vue'
      ),
    TransferObjekt: () =>
      import(
        '@/modules/point-of-sale/components/warehouse/warehouse-module/Transfer.vue'
      ),
    PrimkaEditManualObjekt: () =>
      import(
        '@/modules/point-of-sale/components/warehouse/warehouse-module/DeliveryNoteEditManual.vue'
      ),
    RecalculationObjekt: () =>
      import(
        '@/modules/point-of-sale/components/warehouse/warehouse-module/Recalculation.vue'
      ),
    RecalculationEditObjekt: () =>
      import(
        '@/modules/point-of-sale/components/warehouse/warehouse-module/RecalculationEdit.vue'
      )
  },
  inject: ['showMsgBox', 'showLoader', 'hideLoader', 'showAlert', 'confirm', 'confirmClose'],
  props: {
    modal: Boolean
  },
  data: () => ({
    primkaobjekt: 'PrimkaObjekt',
    primkaEditObjekt: 'PrimkaEditObjekt',
    writeOffObjekt: 'WriteOffObjekt',
    transferObjekt: 'TransferObjekt',
    primkaRucniUnosObjekt: 'PrimkaRucniUnosObjekt',
    primkaEditManualObjekt: 'PrimkaEditManualObjekt',
    recalculationObjekt: 'RecalculationObjekt',
    recalculationEditObjekt: 'RecalculationEditObjekt',
    warehouses: [],
    currency: 'EUR',
    deliveryNumber: '',
    warehouseName: '',
    selectedWarehouse: '',
    ukupnoBezPdv: undefined,
    ukupnoBrutto: undefined,
    fobDescription: '',
    inputTaxTotal: undefined,
    datumKreiranja: 0,
    brojDokumenta: '',
    dobavljac: '',
    selectedRows: [],
    deliveryNumberSearch: '',
    reportFromMenu: undefined,
    reportToMenu: undefined,
    pointsOfSale: [],
    companies: [],
    listeners: [],
    selectedLocation: undefined,
    selectedDeliveryNote: undefined,
    disableButton: true,
    dateFrom: new Date(new Date().getFullYear(), new Date().getMonth(), 2)
      .toISOString()
      .substr(0, 10),
    dateTo: new Date().toISOString().substr(0, 10),
    headers: [
      {
        text: 'Datum',
        align: 'left',
        sortable: false,
        value: 'created'
      },
      { text: 'Oznaka temelja', value: 'delivery_number' },
      { text: 'Dobavljač', value: 'customer_name' },
      { text: 'Iznos', value: 'ukupnoBezPdv' },
      { text: 'Tip dokumenta', value: 'type' }
    ],
    stavke: [],
    primke: [],
    documentTypes: [
      { name: 'Primka', type: 'delivery_notes' },
      { name: 'Otpremnica', type: 'shipment_notes' },
      { name: 'Međuskladišnica', type: 'inventory_transfers' },
      { name: 'Knjižno odobrenje', type: 'credit_notes' }
    ],
    documentType: { name: 'Primka', type: 'delivery_notes' },
    viewType: 'pdf',
    showTableData: true,
    skladiste: undefined,
    skladisteLabel: undefined,
    fiveDecimalStartTimestamp: 1697443176,
    primkaZaEdit: undefined,
    tipDokumenta: '',
    akcija: '',
    transferData: undefined,
    recalculationData: undefined,
    deliveryNoteManualData: undefined,
    refundData: undefined,
    taxData: undefined,
    totalData: undefined,
    loading: false,
    absolute: true,
    opacity: 0.46,
    overlay: true,
    defaultAssociates: [],
    associates: [],
    selectedAssociate: undefined
  }),
  async mounted () {
    var docRef = df.doc(
      `user_locations/${auth.currentUser.uid}.${state.getCurrentCompany().id}`
    )
    docRef.onSnapshot((doc) => {
      if (
        doc &&
        doc.data() &&
        doc.data().locations &&
        Object.keys(doc.data().locations).length > 0
      ) {
        const data = doc.data()
        if (data?.locations) {
          this.pointsOfSale = Object.keys(data.locations)
            .map((key) => {
              if (
                state.getPointOfSale().id &&
                data.locations[key].id === state.getPointOfSale().id
              ) {
                this.selectedLocation = data.locations[key]
                this.setWarehouseChoices()
              }
              return data.locations[key]
            })
            .filter((location) => location?.resource_status === 'ACTIVE')
            .sort((location1, location2) => {
              if (location1.id < location2.id) return -1
              if (location1.id > location2.id) return 1
            })
        }
      }
    })

    df.doc(`company_associates/${state.getCurrentCompany().id}`)
      .get()
      .then((resp) => {
        if (resp && resp.data()) {
          this.defaultAssociates = Object.keys(resp.data().associates)
            .map((key) => {
              return resp.data().associates[key]
            })
            .filter((associate) => associate.status === 'OK')

          this.associates = clone(this.defaultAssociates.sort((a, b) => a.name.localeCompare(b.name)))
        }
      })
  },
  computed: {
    formatDateTo () {
      return new Intl.DateTimeFormat('hr-HR', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      }).format(new Date(this.dateTo))
    },
    formatDateFrom () {
      return new Intl.DateTimeFormat('hr-HR', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      }).format(new Date(this.dateFrom))
    }
  },
  methods: {
    formatNumber (num, divide = true) {
      var value = num
      if (divide) {
        value = num / 100
      }

      if (value % 1 === 0) {
        return value.toString().replace('.', ',') + ',00'
      } else {
        return value.toFixed(2).replace('.', ',')
      }
    },
    async onRowsChange () {
      const selectedData = this.primke.filter(item =>
        this.selectedRows.includes(item.id)
      )

      const selectedObject = selectedData[0] || null

      if (selectedObject && selectedObject.doc_type === 'transfer') {
        const transfer = await df
          .doc(`warehouses/${this.selectedWarehouse.value}/write_off/${selectedObject.id}`)
          .get()
        this.transferData = transfer.data().write_off_items
      } else {
        this.transferData = undefined
      }

      if (selectedObject && selectedObject.doc_type === 'recalculation') {
        const transfer = await df
          .doc(`warehouses/${this.selectedWarehouse.value}/recalculation/${selectedObject.id}`)
          .get()
        this.recalculationData = transfer.data().recalculation_items
      } else {
        this.recalculationData = undefined
      }

      this.deliveryNoteManualData = []
      if (selectedObject && selectedObject.doc_type === 'deliverynote_manual') {
        this.totalData = [{ total: this.formatNumber(selectedObject.total_brutto) }]

        const taxOrder = ['0%', '5%', '13%', '25%']
        if (selectedObject && selectedObject.taxes) {
          const taxData = Object.entries(selectedObject.taxes)
            .map(([key, value]) => {
              if (!value.base || value.base === 0) {
                return null
              }

              return {
                percentage: key + '%',
                base: this.formatNumber(value.base),
                amount: this.formatNumber(value.amount)
              }
            })
            .filter(item =>
              item !== null &&
              item.percentage !== 'Ukupno:%' &&
              item.percentage !== 'Ukupno' &&
              item.base !== 'Ukupno:' &&
              item.base !== 'Ukupno'
            )
            .sort((a, b) => {
              const aIndex = taxOrder.indexOf(a.percentage.replace(':', '').replace('%', ''))
              const bIndex = taxOrder.indexOf(b.percentage.replace(':', '').replace('%', ''))
              return aIndex - bIndex
            })
          this.taxData = taxData
        }

        this.refundData = []
        Object.keys(selectedObject.extended_items).forEach(key => {
          var it = selectedObject.extended_items[key]

          if (it.id === '-1') {
            var quantityPn = it.warehouse_data.quantity / 1000
            var totalPn = it.warehouse_data.value ? it.warehouse_data.value / 10000 : it.warehouse_data.wholesale_price / 100

            this.refundData = [
              {
                quantity: this.formatNumber(quantityPn, false),
                total: this.formatNumber(totalPn, false)
              }
            ]

            return
          }

          var ukupnaCijena = 0
          if (it.warehouse_data.wholesale_price > 0) {
            this.pdvIncluded = true
            ukupnaCijena = (it.warehouse_data.wholesale_price / 100).toString()
          }

          if (it.warehouse_data.value > 0) {
            this.pdvIncluded = false

            ukupnaCijena = (it.warehouse_data.value / 10000).toString()
          }

          var cijena = 0
          if (it.warehouse_data.sale_price > 0) {
            cijena = (it.warehouse_data.sale_price / 100).toString()
          }

          if (it.warehouse_data.received_amount_purchase_price > 0) {
            cijena = (
              it.warehouse_data.received_amount_purchase_price / 100000
            ).toString()
          }

          this.deliveryNoteManualData.push({
            artikl: it.item.name,
            jedinica: it.warehouse_data.received_amount_measure_unit,
            kolicina: it.warehouse_data.received_amount_quantity / 1000,
            ukupno: ukupnaCijena,
            cijena: cijena
          })
        })
      }
    },
    showEditTitle () {
      const primka = this.primke.find(primka =>
        this.selectedRows.includes(primka.id) &&
        primka.type === 'delivery_notes'
      )

      switch (primka.doc_type) {
        case 'creditnote':
          return 'Uredi knjižno odobrenje'
        case 'deliverynote':
          return 'Uredi primku'
        default:
          return 'Uredi primku'
      }
    },
    showDoc () {
      this.$modal.show('documents')
    },
    hideDoc () {
      this.$modal.hide('documents')
      this.unclickRow()
      this.getDocuments()
    },
    showDeliveryNote () {
      this.$modal.show('deliveryNoteManual')
    },
    hideDeliveryNote () {
      this.$modal.hide('deliveryNoteManual')
    },
    showRecalculation () {
      this.$modal.show('recalculation')
    },
    hideRecalculation () {
      this.$modal.hide('recalculation')
      this.unclickRow()
      this.getDocuments()
    },
    showTransfer () {
      this.$modal.show('transfer')
    },
    hideTransfer () {
      this.$modal.hide('transfer')
      this.unclickRow()
      this.getDocuments()
    },
    showWriteOff () {
      this.$modal.show('writeoff')
    },
    hideWriteOff () {
      this.$modal.hide('writeoff')
      this.unclickRow()
      this.getDocuments()
    },
    hideDocEdit () {
      this.$modal.hide('edit')
      this.unclickRow()
      this.getDocuments()
    },
    hideDocManualEdit () {
      this.$modal.hide('editManual')
      this.unclickRow()
      this.getDocuments()
    },
    showRecalculationEdit () {
      this.primkaZaEdit = this.primke.find(
        (primka) =>
          this.selectedRows.includes(primka.id) &&
          primka.type === 'delivery_notes'
      )
      this.tipDokumenta = 'uskladivanje'
      this.akcija = 'update'
      this.$modal.show('recalculationEdit')
    },
    hideRecalculationEdit () {
      this.$modal.hide('recalculationEdit')
      this.unclickRow()
      this.getDocuments()
    },
    showWarehouseLabel (realType, type) {
      if (type === 'inventory_transfer') {
        switch (realType) {
          case 'delivery_note':
            return 'M - primka'
          case 'shipment_note':
            return 'M - otpremnica'
        }
      }
    },
    showDocumentType (realType, type, subtype = '') {
      if (type === 'inventory_transfer') {
        switch (realType) {
          case 'delivery_note':
            return 'M - primka'
          case 'shipment_note':
            return 'M - otpremnica'
        }
      }

      if (type === 'delivery_notes' && subtype !== '') {
        switch (subtype) {
          case 'creditnote':
            return 'Knjižno odobrenje'
          case 'writeoff':
            return 'Otpis'
          case 'transfer':
            return 'Prebacivanje'
          case 'deliverynote_manual':
            return 'Primka'
          case 'recalculation':
            return 'Usklađivanje'
        }
      }

      if (realType === 'delivery_note') {
        return 'Primka'
      }
    },
    warehouseSelected () {
      this.disableButton = false
    },
    setWarehouseChoices () {
      if (this.selectedLocation && this.selectedLocation.id) {
        this.getAllWarehouses(this.selectedLocation.id)
      }
    },
    formatCurrency (item, divider = 100) {
      return this.$options.filters
        .money(item, divider, this.currency)
        .replace('€', '')
    },
    formatDate: function (date) {
      if (date === '') {
        return ''
      }
      date = new Date(date * 1000)

      return moment(date).format('DD.MM.YYYY')
    },
    async getDocuments () {
      this.primke = []
      const dateFrom = new Date(this.dateFrom).getTime() / 1000
      const dateTo = new Date(this.dateTo).getTime() / 1000
      var query = df
        .collection(`warehouses/${this.selectedWarehouse.value}/delivery_notes`)
        .where('order_date', '>=', dateFrom)
        .where('order_date', '<=', dateTo + 86399)
        .orderBy('order_date', 'desc')
      // .limit(15)

      if (this.deliveryNumberSearch !== '') {
        query = query.where('delivery_number', '==', this.deliveryNumberSearch)
      }

      if (this.selectedAssociate && this.selectedAssociate.id !== '') {
        query = query.where('customer_name', '==', this.selectedAssociate.name)
      }

      query.get().then((documentSnapshots) => {
        this.loading = false

        if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
          // Dohvati otpremnice za meduskladisnicu
          var shipmentquery = df
            .collection(
              `warehouses/${this.selectedWarehouse.value}/shipment_notes`
            )
            .where('order_date', '>=', dateFrom)
            .where('order_date', '<=', dateTo + 86399)
            .where('type', '==', 'inventory_transfer')
            .orderBy('order_date', 'desc')
          // .limit(15)

          // if (this.deliveryNumberSearch !== '') {
          //   query = query.where('delivery_number', '==', this.deliveryNumberSearch)
          // }

          shipmentquery.get().then((shipmentDocumentSnapshots) => {
            shipmentDocumentSnapshots.docs.forEach((item) => {
              var otpremnica = item.data()

              // Sluzi za dio da li je skladiste koje prima ili s kojeg se skidalo u slucaju medjuskladisnice
              otpremnica.realType = 'shipment_note'
              otpremnica.skladiste = otpremnica.ship_name
              otpremnica.skladisteLabel = 'Odredišno skladište'

              let ukupno = 0
              for (const prop in otpremnica.extended_items) {
                const rabat =
                  otpremnica.extended_items[prop].warehouse_data
                    .supplier_rebate / 10000
                const totalPriceWithRabat =
                  otpremnica.extended_items[prop].warehouse_data
                    .received_amount_purchase_price -
                  otpremnica.extended_items[prop].warehouse_data
                    .received_amount_purchase_price *
                  rabat
                const totalPriceNoPdv =
                  (totalPriceWithRabat *
                    otpremnica.extended_items[prop].warehouse_data
                      .received_amount_quantity) /
                  1000
                ukupno += totalPriceNoPdv
              }
              otpremnica.ukupno = ukupno.toFixed(2)
              this.primke.push(otpremnica)
            })
          })

          documentSnapshots.docs.forEach((item) => {
            var primka = item.data()

            if (primka.type === '') {
              primka.type = 'delivery_note'
            }

            // Sluzi za dio da li je skladiste koje prima ili s kojeg se skidalo u slucaju medjuskladisnice
            primka.realType = 'delivery_note'
            if (primka.type === 'inventory_transfer') {
              primka.skladiste = primka.ship_name
              primka.skladisteLabel = 'Izvorišno skladište'
            }

            let ukupno = 0
            for (const prop in primka.extended_items) {
              const rabat =
                primka.extended_items[prop].warehouse_data.supplier_rebate /
                10000
              // NAKON OVOG DATUMA UVEDENA OVA CIJENA NA 5 DECIMALA SAMO ZA OBICNE PRIMKE
              var receivedAmountPurchasePrice =
                primka.extended_items[prop].warehouse_data
                  .received_amount_purchase_price
              if (
                (primka.type === 'delivery_note' ||
                  primka.type === 'delivery_notes') &&
                primka.created > this.fiveDecimalStartTimestamp
              ) {
                receivedAmountPurchasePrice /= 1000
              }
              const totalPriceWithRabat =
                receivedAmountPurchasePrice -
                receivedAmountPurchasePrice * rabat
              const totalPriceNoPdv =
                (totalPriceWithRabat *
                  primka.extended_items[prop].warehouse_data
                    .received_amount_quantity) /
                1000
              ukupno += totalPriceNoPdv
            }

            primka.ukupno = ukupno.toFixed(2)
            if (primka.total_base_price) {
              primka.ukupno = primka.total_base_price
            }

            this.primke.push(primka)
          })
        }
      })
    },
    async getAllWarehouses (locationId) {
      const query = df
        .collection('warehouses/')
        .where('company_id', '==', state.getCurrentCompany().id)
        .where('location_id', '==', locationId)

      query.get().then((snapshot) => {
        snapshot.forEach((doc) => {
          const warehouse = doc.data()
          if (warehouse.selling_warehouse === true) {
            this.selectedWarehouse = {
              name: warehouse.name,
              value: warehouse.id,
              warehouse: warehouse
            }
            this.warehouseSelected()
          }
          this.warehouses.push({
            name: warehouse.name,
            value: warehouse.id,
            warehouse: warehouse
          })
        })
      })
    },
    unclickRow () {
      this.stavke = []
    },
    recursiveInvert (obj) {
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          if (typeof obj[key] === 'number') {
            obj[key] = obj[key] * -1
          } else if (typeof obj[key] === 'object' && obj[key] !== null) {
            this.recursiveInvert(obj[key])
          }
        }
      }
    },
    rowClicked (r) {
      var row = clone(r)
      this.toggleSelection(row.id)

      this.stavke = []
      this.deliveryNumber = row.delivery_number
      this.warehouseName = row.warehouse_name
      this.ukupnoBezPdv = row.total_base_price
        ? clone(row.total_base_price)
        : 0
      this.inputTaxTotal = row.total_tax ? clone(row.total_tax) : 0
      this.ukupnoBrutto = row.total_brutto ? clone(row.total_brutto) : 0

      this.datumKreiranja = row.created
      this.dobavljac = row.customer_name
      this.fobDescription = row.fob_description
      this.selectedDeliveryNote = row
      this.skladiste = row.skladiste
      this.skladisteLabel = row.skladisteLabel
      this.brojDokumenta = row.delivery_number

      if (row && row.doc_type) {
        switch (row.doc_type) {
          case 'deliverynote':
            this.documentType = { name: 'Primka', type: 'delivery_notes' }
            break
          case 'creditnote':
            this.documentType = { name: 'Knjižno odobrenje', type: 'delivery_notes' }
            break
        }
      }

      var ukupnoBezPdvIsEmpty = false

      if (this.ukupnoBezPdv === 0 || this.ukupnoBezPdv === undefined) {
        ukupnoBezPdvIsEmpty = true
      }
      for (const prop in row.extended_items) {
        const rabat =
          row.extended_items[prop].warehouse_data.supplier_rebate / 10000

        var receivedAmountPurchasePrice = clone(
          row.extended_items[prop].warehouse_data.received_amount_purchase_price
        )
        // NAKON OVOG DATUMA UVEDENA OVA CIJENA NA 5 DECIMALA SAMO ZA OBICNE PRIMKE
        if (
          (row.type === 'delivery_note' || row.type === 'delivery_notes') &&
          row.created > this.fiveDecimalStartTimestamp
        ) {
          receivedAmountPurchasePrice /= 1000
        }

        const totalPriceWithRabat =
          receivedAmountPurchasePrice - receivedAmountPurchasePrice * rabat
        var totalPriceNoPdv =
          (Math.abs(totalPriceWithRabat) *
            row.extended_items[prop].warehouse_data.received_amount_quantity) /
          1000

        if (ukupnoBezPdvIsEmpty) {
          this.ukupnoBezPdv += totalPriceNoPdv
        }

        const inputTax =
          Math.abs(totalPriceNoPdv) *
          (row.extended_items[prop].warehouse_data.vat / 10000)

        if (this.inputTaxTotal === 0 || this.inputTaxTotal === undefined) {
          this.inputTaxTotal += inputTax
        }

        this.stavke.push({
          id: row.extended_items[prop].id,
          name: row.extended_items[prop].item.name,
          quantity:
            row.extended_items[prop].warehouse_data.received_amount_quantity /
            1000,
          measureUnit:
            row.extended_items[prop].warehouse_data
              .received_amount_measure_unit,
          purchasePrice: row.extended_items[prop].warehouse_data.received_amount_purchase_price / 1000,
          supplierRebate:
            row.extended_items[prop].warehouse_data.supplier_rebate,
          totalPriceWithRabat: row.extended_items[prop].warehouse_data.purchase_price,
          totalPriceNoPdv: row.extended_items[prop].warehouse_data.value / 100,
          inputTax: row.extended_items[prop].warehouse_data.vat_amount,
          pdv: row.extended_items[prop].warehouse_data.vat,
          refundPrice: row.extended_items[prop].warehouse_data.retail_price,
          refundPriceTotal: row.extended_items[prop].warehouse_data.retail_price * (row.extended_items[prop].warehouse_data.quantity / 1000)
        })
      }
    },
    toggleSelection (keyID) {
      if (this.selectedRows.includes(keyID)) {
        this.selectedRows = this.selectedRows.filter(
          (selectedKeyID) => selectedKeyID !== keyID
        )
      } else {
        this.selectedRows.splice(0)
        this.selectedRows.push(keyID)
      }

      this.onRowsChange()
    },
    changeWidth () {
      this.showLoader('', { opacity: 1 })
      document.getElementById('table_info').style.maxWidth =
        this.a4PortraitWidth
      document.getElementById('table_info').style.minWidth =
        this.a4PortraitWidth
      document.getElementById('table_info').style.fontSize = '80%'

      this.generatePdf()
    },
    async generatePdf () {
      const that = this
      // eslint-disable-next-line new-cap
      var doc = await initPdf()
      doc = await setTable(doc, '#title', 10)
      var finalY = doc.lastAutoTable.finalY || 10

      doc = await setTable(doc, '#firma', finalY + 10)
      finalY = doc.lastAutoTable.finalY || 10

      doc = await setTable(doc, '#tableTraffic', finalY + 10)
      finalY = doc.lastAutoTable.finalY || 10

      doc = await setPageNumber(doc)

      doc.save('Skladišni dokument.pdf')

      document.getElementById('table_info').style.maxWidth = that.tableWidth
      document.getElementById('table_info').style.minWidth = that.tableWidth
      document.getElementById('table_info').style.fontSize = '100%'
      this.viewType = 'pdf'
      that.hideLoader()
    },
    generateExcel () {
      this.showLoader('', { opacity: 1 })
      this.viewType = 'excel'
      setTimeout(() => {
        var tables = ['title', 'firma', 'tableTraffic']
        var fileName = 'Skladišni dokument.xlsx'

        saveExcel(tables, fileName)

        this.viewType = 'pdf'
        this.hideLoader()
      }, 1000)
    },

    callDeleteDeliveryNote (primkaZaDelete) {
      this.loading = true

      var deliverynoteApi = functions.httpsCallable('deliverynoteapi')
      var resourceID = primkaZaDelete.id

      deliverynoteApi({
        action: {
          operation: 'set',
          entity: 'document_note',
          resource_id: resourceID,
          params: {
            action: 'delete',
            location_id: primkaZaDelete.location_id,
            document_type: primkaZaDelete.doc_type,
            selling_warehouse: this.selectedWarehouse.warehouse.selling_warehouse,
            warehouse_id: this.selectedWarehouse.value
          }
        }
      }).then((result) => {
        if (result.data.code === 200) {
          this.loading = false
          this.showMsgBox({
            text: 'Uspješno obrisan dokument.',
            actions: ['cancel'],
            cancelBtnText: this.$t('$vuetify.close'),
            color: 'error'
          })
          this.unclickRow()
          this.getDocuments()
          this.selectedRows = []
        } else {
          this.loading = false
          this.showMsgBox({
            text: 'Nije uspjelo brisanje dokumenta.',
            actions: ['cancel'],
            cancelBtnText: this.$t('$vuetify.close'),
            color: 'error'
          })
        }
      })
    },
    deleteDeliveryNote () {
      var primkaZaDelete = this.primke.find(
        (primka) =>
          this.selectedRows.includes(primka.id) &&
          primka.type === 'delivery_notes'
      )

      this.confirm({
        title: '',
        message: `Jeste li sigurni da želite obrisati dokument ${primkaZaDelete.delivery_number}?`,
        options: {
          toolbar: true,
          width: 410,
          confirmText: 'DA',
          cancelText: this.$t('$vuetify.close')
        }
      }).then(async (resp) => {
        if (resp) {
          this.callDeleteDeliveryNote(primkaZaDelete)
        }

        this.hideLoader()
        this.confirmClose()
      })
    },
    editDeliveryNote () {
      this.primkaZaEdit = this.primke.find(
        (primka) =>
          this.selectedRows.includes(primka.id) &&
          primka.type === 'delivery_notes'
      )

      switch (this.primkaZaEdit.doc_type) {
        case 'deliverynote':
          this.tipDokumenta = 'primka'
          break
        case 'creditnote':
          this.tipDokumenta = 'knjizno_odobrenje'
          break
        default:
          this.tipDokumenta = 'primka'
      }
      this.akcija = 'update'
      this.$modal.show('edit')
    },
    editDeliveryNoteManual () {
      this.primkaZaEdit = this.primke.find(
        (primka) =>
          this.selectedRows.includes(primka.id) &&
          primka.type === 'delivery_notes'
      )

      this.tipDokumenta = 'primka'
      this.akcija = 'update'
      this.$modal.show('editManual')
    },
    editCreditNote () {
      this.primkaZaEdit = this.primke.find(
        (primka) =>
          this.selectedRows.includes(primka.id) &&
          primka.type === 'delivery_notes'
      )
      this.tipDokumenta = 'knjizno_odobrenje'
      this.akcija = 'add'
      this.$modal.show('edit')
    }
  }
}
</script>
<style scoped>
.enabledbutton {
  background-color: #2c97c5 !important;
  border-color: #2c97c5 !important;
  color: white;
  font-weight: 700;
}

.naslov {
  background-color: #d3d3d3;
  border: 1px solid black;
}

.selectedrow-456 {
  background-color: #2c97c5 !important;
  border-color: #2c97c5 !important;
  color: white;
  font-weight: 700;
}

.createDelivery>>>.vm--modal {
  border-radius: 25px !important;
}

.editDelivery>>>.vm--modal {
  border-radius: 25px !important;
}
</style>
