<template>
  <div style="
          font-family:
          Raleway,
          sans-serif;
          padding-top:
          10px">
    <table justify="center"
      style="width: 100% !important; border-collapse: collapse;"
      border="0"
      cellspacing="0"
      id="tableTraffic"
      v-if="recalculationData && recalculationData.length > 0">
      <tbody>
        <template v-for="(item, index) in recalculationData">
          <tr :key="`item-${index}-date`">
            <td colspan="4"
              style="padding: 5px 0; text-align: left">
              <strong>Datum važenja:</strong>{{ formatDateDDMMYYY(item.date) }}
            </td>
          </tr>
          <tr :key="`item-${index}-name`">
            <td colspan="4"
              style="padding: 5px 0; text-align: left">
              <strong>{{ item.item_name }}</strong>
            </td>
          </tr>
          <tr :key="`item-${index}-quantity`">
            <td colspan="4"
              style="padding: 5px 0; text-align: left">
              {{ item.action }} {{ formatCurrency(item.quantity / 1000, 1) }} {{ item.unit }}
            </td>
          </tr>
          <tr :key="`item-${index}-space`">
            <td colspan="4"
              style="padding: 5px 0; text-align: left">&nbsp;</td>
          </tr>
          <tr :key="`item-${index}-reason`">
            <td colspan="4"
              style="padding: 5px 0; text-align: left">
              <strong>Razlog:</strong> {{ item.reason }}
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>
<script>
import moment from 'moment'

export default {
  components: {
  },
  inject: ['showMsgBox', 'showLoader', 'hideLoader', 'showAlert'],
  props: {
    recalculationData: Array,
    brojDokumenta: String,
    documentDate: String,
    datumKreiranja: Number,
    showTableData: Boolean,
    viewType: String
  },
  data: () => ({
    currency: 'EUR'
  }),
  async mounted () {
  },
  methods: {
    formatDateDDMMYYY (dateString) {
      if (!dateString) return ''
      const parts = dateString.split('-')
      if (parts.length !== 3) return dateString
      return `${parts[2]}.${parts[1]}.${parts[0]}`
    },
    formatCurrency (item, divider = 100) {
      return this.$options.filters
        .money(item, divider, this.currency)
        .replace('€', '')
    },
    formatDate: function (date) {
      if (date === '') {
        return ''
      }
      date = new Date(date * 1000)

      return moment(date).format('DD.MM.YYYY')
    }
  }
}
</script>
<style scoped>
.enabledbutton {
  background-color: #2c97c5 !important;
  border-color: #2c97c5 !important;
  color: white;
  font-weight: 700;
}

.naslov {
  background-color: #d3d3d3;
  border: 1px solid black;
}

.selectedrow-456 {
  background-color: #2c97c5 !important;
  border-color: #2c97c5 !important;
  color: white;
  font-weight: 700;
}

.createDelivery>>>.vm--modal {
  border-radius: 25px !important;
}

.editDelivery>>>.vm--modal {
  border-radius: 25px !important;
}
</style>
