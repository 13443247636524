<template>
  <div style="font-family: Raleway, sans-serif; padding-top: 10px">
    <table justify="center"
      style="width: 100% !important"
      border="1"
      cellspacing="0"
      id="tableTraffic"
      v-if="showTableData && stavke.length > 0">
      <thead>
        <tr :class="['header', 'naslov']"
          style="text-align: center">
          <th colspan="11">Artikli</th>
        </tr>
      </thead>
      <tbody>
        <tr :class="['header', 'naslov']"
          style="font-weight: bold; text-align: left">
          <th>Artikl</th>
          <th>Kol.</th>
          <th>Jed. mjere</th>
          <th>Nabav. cij.</th>
          <th>Rabat (%)</th>
          <th>Cijena</th>
          <th>Ukupno</th>
          <th>PDV (%)</th>
          <th>Predporez</th>
          <th>Brutto</th>
          <th>Pn</th>
        </tr>
        <tr v-for="item in stavke"
          :key="item.id">
          <td>{{ item.name }}</td>
          <td style="text-align: right">
            {{
              viewType === "pdf"
                ? formatCurrency(item.quantity, 1)
                : item.quantity
            }}
          </td>
          <td>{{ item.measureUnit }}</td>
          <td style="text-align: right">
            {{
              viewType === "pdf"
                ? formatCurrency(item.purchasePrice)
                : item.purchasePrice / 100
            }}
          </td>
          <td style="text-align: right">
            {{
              viewType === "pdf"
                ? formatCurrency(item.supplierRebate)
                : item.supplierRebate / 100
            }}
          </td>
          <td style="text-align: right">
            {{
              viewType === "pdf"
                ? formatCurrency(item.totalPriceWithRabat)
                : item.totalPriceWithRabat / 100
            }}
          </td>
          <td style="text-align: right">
            {{
              viewType === "pdf"
                ? formatCurrency(item.totalPriceNoPdv)
                : item.totalPriceNoPdv / 100
            }}
          </td>
          <td style="text-align: right">
            {{
              viewType === "pdf"
                ? formatCurrency(item.pdv)
                : item.pdv / 100
            }}
          </td>
          <td style="text-align: right">
            {{
              viewType === "pdf"
                ? formatCurrency(item.inputTax)
                : item.inputTax / 100
            }}
          </td>
          <td style="text-align: right">
            {{
              viewType === 'pdf'
                ? formatCurrency(item.inputTax + item.totalPriceNoPdv)
                : (item.inputTax + item.totalPriceNoPdv) / 100
            }}
          </td>
          <td style="text-align: right">
            {{
              viewType === "pdf"
                ? formatCurrency(item.refundPrice)
                : item.refundPrice / 100
            }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>

export default {
  components: {
  },
  inject: ['showMsgBox', 'showLoader', 'hideLoader', 'showAlert'],
  props: {
    deliveryNoteManualData: Array,
    stavke: Array,
    showTableData: Boolean,
    viewType: String
  },
  data: () => ({
    currency: 'EUR'
  }),
  async mounted () {
  },
  methods: {
    formatCurrency (item, divider = 100) {
      return this.$options.filters
        .money(item, divider, this.currency)
        .replace('€', '')
    }
  }
}
</script>
<style scoped>
.enabledbutton {
  background-color: #2c97c5 !important;
  border-color: #2c97c5 !important;
  color: white;
  font-weight: 700;
}

.naslov {
  background-color: #d3d3d3;
  border: 1px solid black;
}

.selectedrow-456 {
  background-color: #2c97c5 !important;
  border-color: #2c97c5 !important;
  color: white;
  font-weight: 700;
}

.createDelivery>>>.vm--modal {
  border-radius: 25px !important;
}

.editDelivery>>>.vm--modal {
  border-radius: 25px !important;
}
</style>
