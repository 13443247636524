<template>
  <v-simple-table dense
    id="podaci"
    class="elevation-1">
    <template v-slot:default>
      <tbody>
        <tr>
          <td class="font-weight-bold">Skladište</td>
          <td>{{ warehouseName }}</td>
        </tr>
        <tr>
          <td class="font-weight-bold">Dobavljač</td>
          <td>{{ dobavljac }}</td>
        </tr>
        <tr>
          <td class="font-weight-bold">Način plaćanja</td>
          <td>{{ fobDescription }}</td>
        </tr>
        <tr>
          <td class="font-weight-bold">Datum</td>
          <td>{{ formatDate(datumKreiranja) }}</td>
        </tr>
        <tr>
          <td class="font-weight-bold">Broj dokumenta</td>
          <td>{{ deliveryNumber }}</td>
        </tr>
        <tr>
          <td class="font-weight-bold">Iznos</td>
          <td>
            {{
              ukupnoBezPdv ? formatCurrency(ukupnoBezPdv) : ""
            }}
          </td>
        </tr>
        <tr>
          <td class="font-weight-bold">Predporez</td>
          <td>
            {{
              inputTaxTotal
                ? formatCurrency(inputTaxTotal)
                : ""
            }}
          </td>
        </tr>
        <tr>
          <td class="font-weight-black">Povratna naknada</td>
          <td class="font-weight-black">
            {{ calculatePn(false) }}
          </td>
        </tr>
        <tr>
          <td class="font-weight-black">Ukupno</td>
          <td class="font-weight-black">
            {{
              ukupnoBrutto
                ? formatCurrency(ukupnoBrutto)
                : formatCurrency(
                  ukupnoBezPdv +
                  inputTaxTotal +
                  calculatePn(true)
                )
            }}
          </td>
        </tr>
        <tr v-if="skladisteLabel && skladiste">
          <td class="font-weight-black">
            {{ skladisteLabel }}
          </td>
          <td class="font-weight-black">
            {{ skladiste }}
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
<script>
import moment from 'moment'

export default {
  components: {
  },
  inject: ['showMsgBox', 'showLoader', 'hideLoader', 'showAlert'],
  props: {
    warehouseName: String,
    dobavljac: String,
    fobDescription: String,
    datumKreiranja: Number,
    deliveryNumber: String,
    ukupnoBezPdv: Number,
    ukupnoBrutto: Number,
    inputTaxTotal: Number,
    selectedDeliveryNote: Object,
    viewType: String,
    skladiste: String,
    skladisteLabel: String
  },
  data: () => ({
    currency: 'EUR'
  }),
  async mounted () {
  },
  methods: {
    formatCurrency (item, divider = 100) {
      return this.$options.filters
        .money(item, divider, this.currency)
        .replace('€', '')
    },
    formatDate: function (date) {
      if (date === '') {
        return ''
      }
      date = new Date(date * 1000)

      return moment(date).format('DD.MM.YYYY')
    },
    calculatePn (returnAsNumber = false) {
      var totalPn = 0
      if (this.selectedDeliveryNote) {
        Object.keys(this.selectedDeliveryNote.extended_items).forEach((key) => {
          var it = this.selectedDeliveryNote.extended_items[key]
          if (
            it.warehouse_data.retail_price &&
            it.warehouse_data.retail_price > 0
          ) {
            totalPn += it.warehouse_data.retail_price * (it.item.stock / 1000)
          }

          if (it.id === '-1') {
            var ukupnaCijena = 0
            if (it.warehouse_data.wholesale_price > 0) {
              ukupnaCijena = (it.warehouse_data.wholesale_price / 100)
            }

            if (it.warehouse_data.value > 0) {
              ukupnaCijena = (it.warehouse_data.value / 10000)
            }

            totalPn += ukupnaCijena * 100
          }
        })

        if (this.selectedDeliveryNote.total_retail_price) {
          totalPn = this.selectedDeliveryNote.total_retail_price
        }

        if (!returnAsNumber) {
          return this.formatCurrency(totalPn)
        } else {
          return totalPn
        }
      }

      if (!returnAsNumber) {
        return ''
      } else {
        return 0
      }
    }
  }
}
</script>
