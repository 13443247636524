<template>
  <v-data-table fixed-header
    :headers="stavkeHeaders"
    :items="stavke"
    hide-default-footer
    dense
    id="stavket"
    height="100%"
    item-key="id"
    class="elevation-1"
    style="height: 35vh; overflow-y: auto"
    :items-per-page="-1">
    <template v-slot:item="{ item }">
      <tr>
        <td>{{ item.name }}</td>
        <td style="text-align: right">
          {{ formatCurrency(item.quantity, 1) }}
        </td>
        <td>{{ item.measureUnit }}</td>
        <td style="text-align: right">
          {{ formatCurrency(item.purchasePrice) }}
        </td>
        <td style="text-align: right">
          {{ formatCurrency(item.supplierRebate) }}
        </td>
        <td style="text-align: right">
          {{ formatCurrency(item.totalPriceWithRabat) }}
        </td>
        <td style="text-align: right">
          {{ formatCurrency(item.totalPriceNoPdv) }}
        </td>
        <td style="text-align: right">
          {{ formatCurrency(item.pdv) }}
        </td>
        <td style="text-align: right">
          {{ formatCurrency(item.inputTax) }}
        </td>
        <td style="text-align: right">
          {{
            formatCurrency(item.inputTax + item.totalPriceNoPdv)
          }}
        </td>
        <td style="text-align: right">
          {{ formatCurrency(item.refundPrice) }}
        </td>
        <td style="text-align: right">
          {{ formatCurrency(item.refundPriceTotal) }}
        </td>
      </tr>
    </template>
  </v-data-table>
</template>
<script>

export default {
  components: {
  },
  inject: ['showMsgBox', 'showLoader', 'hideLoader', 'showAlert'],
  props: {
    stavke: Array,
    viewType: String
  },
  data: () => ({
    currency: 'EUR',
    stavkeHeaders: [
      { text: 'Artikl', value: 'name' },
      { text: 'Količina', value: 'quantity', align: 'end' },
      { text: 'Jedinica mjere', value: 'measureUnit' },
      { text: 'Nabavna cijena', value: 'purchasePrice', align: 'end' },
      { text: 'Rabat (%)', value: 'supplierRebate', align: 'end' },
      { text: 'Cijena', value: 'totalPriceWithRabat', align: 'end' },
      { text: 'Ukupno', value: 'totalPriceNoPdv', align: 'end' },
      { text: 'PDV (%)', value: 'pdv', align: 'end' },
      { text: 'Predporez', value: 'inputTax', align: 'end' },
      { text: 'Brutto', value: 'measureUnit', align: 'end' },
      { text: 'Pn', value: 'refundPrice', align: 'end' },
      { text: 'Pn brutto', value: 'refundPriceTotal', align: 'end' }
    ]
  }),
  async mounted () {
  },
  methods: {
    formatCurrency (item, divider = 100) {
      return this.$options.filters
        .money(item, divider, this.currency)
        .replace('€', '')
    }
  }
}
</script>
